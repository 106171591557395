document.addEventListener('DOMContentLoaded', () => {
  if (localStorage.getItem("accept") > 0){
    const currentTheme = localStorage.getItem("theme");
    document.documentElement.setAttribute('data-theme', currentTheme);
    document.getElementById("notification").style.display = "none";
  }
});

function SetTheme(e) {
  document.documentElement.setAttribute('data-theme', e);
  localStorage.setItem("theme", e);
  if (localStorage.getItem("accept") < 1){
    localStorage.setItem("accept", 1);
  }
};

window.SetTheme = SetTheme;
